
















































































import {Component, Watch} from 'vue-property-decorator';
import {
  dispatchDeletePatient,
  dispatchExpireDataByModuleName,
  dispatchGetPatientByEmail,
  dispatchGetPatientByFirebaseId,
  dispatchGetPatientById,
  dispatchGetPatientByPatientIdentifier,
  dispatchGetPatientsForTable,
} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {crudModules} from '@/constants/globalConstants';

@Component({components: {ConfirmationModal}})
export default class AdminPatients extends AppComponent {
  public headers = [
    {
      text: 'First Name',
      sortable: true,
      filterable: true,
      value: 'fname',
      align: 'left',
    },
    {
      text: 'Last Name',
      sortable: true,
      filterable: true,
      value: 'lname',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      filterable: true,
      value: 'patientContactInfo.email',
      align: 'left',
    },
    {
      text: 'Phone Number',
      sortable: true,
      filterable: true,
      value: 'patientContactInfo.phoneNumber',
      align: 'left',
    },
    {
      text: 'Patient identifier',
      sortable: true,
      filterable: true,
      value: 'patientIdentifier',
      align: 'left',
    },
    {
      text: 'City',
      sortable: true,
      value: 'patientContactInfo.city',
      align: 'left',
    },
    {
      text: 'State',
      sortable: true,
      value: 'patientContactInfo.state',
      align: 'left',
    },
    {
      text: 'Firebase Id',
      sortable: true,
      filterable: true,
      value: 'patientFirebaseId',
      align: 'left',
    },
    {
      text: 'Actions',
      align: 'center',
      value: 'patient_id',
    },
  ];

  public search = '';
  public fetchingPatientData = false;
  public showAllPatients = false;
  public emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
  public firebaseUserUUIDRegex = new RegExp(/^[a-zA-Z0-9]{28}$/);
  // regex for strings like this oen 5b41667c-24c0-4399-a1a9-4576fd452707
  public UUIDRegex = new RegExp(/^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/);
  // regex for strings like this one SHJ8AjcofsMLuEtu
  public patientIdentifierRegex = new RegExp(/^[a-zA-Z0-9]{16}$/);
  public internalPatientList: any[] = [];
  public userTyping = false;
  public timeout: any = null;
  public debounceTime = 750;
  private patientList: any[] = [];

  get patients() {
    return this.patientList;
  }

  @Watch('showAllPatients')
  public onShowPatientsTableChange() {
    if (this.showAllPatients) {
      this.fetchData();
    }
  }

  @Watch('patients')
  public onPatientsChange() {
    this.internalPatientList = this.patients;
  }

  @Watch('search')
  public async debounceSearch() {
    this.userTyping = true;
    if (this.showAllPatients) {
      // only available in search mode
      return;
    }
    if (this.userTyping) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        await this.searchPatient();
      }, this.debounceTime);
    } else {
      await this.searchPatient();
    }
  }

  public async searchPatient() {

    let patient = null;
    const searchTrimmed = this.search.trim();
    // email regex
    if (this.emailRegex.test(searchTrimmed)) {
      patient = await dispatchGetPatientByEmail(this.$store, searchTrimmed);
    }
    // firebase user uuid regex
    if (this.firebaseUserUUIDRegex.test(searchTrimmed)) {
      patient = await dispatchGetPatientByFirebaseId(this.$store, searchTrimmed);
    }
    // uuid regex
    if (this.UUIDRegex.test(searchTrimmed)) {
      patient = await dispatchGetPatientById(this.$store, searchTrimmed);
    }
    // patient identifier regex
    if (this.patientIdentifierRegex.test(searchTrimmed)) {
      patient = await dispatchGetPatientByPatientIdentifier(this.$store, searchTrimmed);
    }
    if (patient) {
      this.$toast.success('Patient retrieved successfully');
      this.internalPatientList = [patient];
    } else {
      this.$toast.error('Patient not found');
      this.internalPatientList = [];
    }
    this.userTyping = false;
  }

  public async mounted() {
    // this.fetchData();
  }

  public async fetchData() {
    this.fetchingPatientData = true;
    this.patientList = await dispatchGetPatientsForTable(this.$store);
    this.fetchingPatientData = false;
  }

  /**
   * Delete a patient and refresh the screen
   */
  public async deletePatient(patientId: string) {
    await dispatchDeletePatient(this.$store, {patientId});
    await this.fetchData();
    /* Expire cache modules */
    dispatchExpireDataByModuleName(this.$store, crudModules.REQUISITIONS);
    dispatchExpireDataByModuleName(this.$store, crudModules.BIOMARKER_RESULTS);
    dispatchExpireDataByModuleName(this.$store, crudModules.VISITS);
    this.$forceUpdate();
  }
}
